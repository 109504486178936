@font-face {
  font-family: 'MariDavid';
  src: url('./assets/fonts/mari-david.ttf');
}

$orange: #C96444;
$yellow: #FDEB89;
$blue: #4BADE9;

.App {
  font-family: 'Source Sans Pro', sans-serif;

  .hero {
    width: 100%;
    background-image: linear-gradient(#B55A3E, #A24D33);
    background-color: $orange;
    background-size: auto;
    background-repeat: repeat;

    .logo-wrapper {
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('./assets/images/logo-backdrop.png');
      width: 240px;
      margin-left: auto;
      margin-right: auto;
      height: 130px;
      position: relative;

      img {
        margin: 0px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -55%);
        height: 90px;
      }
    }

    .inner {
      display: grid;
      grid-template-columns: 45fr 50fr;
      grid-gap: 100px;
      padding: 10vh 7vw;

      .left {
        width: 100%;
        min-width: 400px;
        
        iframe {
          background-color: #00000020;
          width: 100%;
          min-height: 300px;
          height: 500px;
        }
      }

      .right {
        color: white;
        margin-top: auto;
        margin-bottom: auto;
        padding-bottom: 50px;

        h3 {
          font-weight: 800;
          font-size: 13pt;
          margin: 0px;
        }

        h1 {
          font-size: 26pt;
          font-weight: 500;
          margin-top: 10px;
          margin-bottom: 30px;
        }

        p {
          line-height: 1.6;
          font-size: 14pt;
          margin: 20px 0px;

          &:last-of-type {
            margin-bottom: 50px;
          }
        }

        a {
          background-color: $yellow;
          padding: 14px 40px;
          font-size: 12pt;
          font-weight: 600;
          border: none;
          border-radius: 8px;
          color: $orange;
          font-family: 'Source Sans Pro', sans-serif;
          cursor: pointer;
          outline: none;
          box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);

          &:hover {
            transform: scale(0.99);
          }
        }
      }
    }
  }

  .form-wrapper {
    padding: 90px 50px;
    min-height: 600px;

    h1 {
      font-family: MariDavid;
      font-size: 35pt;
      font-weight: 400;
      letter-spacing: 1.2px;
    }
    
    h1, p {
      text-align: center;
    }

    p {
      color: #504F4F;
      font-size: 13pt;
      line-height: 1.6;
      margin-bottom: 40px;
    }
    
    h1, p, form {
      max-width: 850px;
      margin-left: auto;
      margin-right: auto;
    }

    form {
      display: flex;
      flex-direction: column;

      label {
        font-size: 11pt;
        font-weight: 600;
        margin-bottom: 10px;
      }

      input {
        outline: none;
        margin-bottom: 30px;
        padding: 15px;
        border: solid 1px #00000020;
        border-radius: 8px;
      }

      input[type=submit] {
        -webkit-appearance: none;
        background-color: $blue;
        padding: 18px 14px;
        font-size: 12pt;
        font-weight: 600;
        border: none;
        border-radius: 8px;
        margin-top: 10px;
        color: white;
        font-family: 'Source Sans Pro', sans-serif;
        cursor: pointer;
        outline: none;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
      }
    }
  }

  .how-it-works {
    background-color: #EDEDED;
    padding: 20px 50px;
    
    h4 {
      text-transform: uppercase;
      color: #979797;
      font-weight: bold;
      padding: 30px 0px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      font-size: 14pt;
    }

    .section {
      background-color: $orange;
      color: white;
      max-width: 850px;
      margin-left: auto;
      margin-right: auto;
      padding: 10px 25px;
      border-radius: 8px;
      margin-bottom: 40px;
      display: flex;

      .left {
        margin-right: 25px;
        margin-top: auto;
        margin-bottom: auto;

        img {
          width: 70px;
        }
      }

      .right {
        h5 {
          font-size: 14pt;
          margin: 15px 0px;
        }
  
        p {
          font-size: 13pt;
          line-height: 1.6;
          margin: 10px 0px 15px;
        }
      }
    }
  }

  .footer {
    text-align: center;
    padding: 30px 0px;
    
    p {
      color: #00000080;

      span {
        margin: 0px 10px;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .hero {
    .inner {
      display: flex !important;
      flex-direction: column;
      padding: 40px !important;

      .left {
        min-width: 100% !important;
        margin: 30px 0px;

        iframe {
          height: 200px !important;
        }
      }

      .right {
        padding: 0px;
        margin: 40px 0px !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .how-it-works {
    .section {
      text-align: center;
      flex-direction: column;

      .left {
        padding: 20px 0px;

        img {
          margin-top: auto;
          margin-bottom: auto;
        }
      }

      .right {
        margin-bottom: 20px;
      }
    }
  }
}